import dynamic from 'next/dynamic';

import { FaqSliceProps } from '@ui/slice-machine/slices/faq-slice/FaqSlice';

const FaqSlice = dynamic<FaqSliceProps>(() =>
  import('@ui/slice-machine/slices/faq-slice/FaqSlice').then(
    (val) => val.FaqSlice
  )
);

export default FaqSlice;
